import { HttpRequest, HttpHandlerFn, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { inject } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";
import { TimeoutError } from "rxjs";
import { AuthService } from "../modules/auth/auth.service";
import { Router } from '@angular/router';
import { NotiflixService } from "../services/notiflix.service";
import { ModalService } from "../components/modal/modal.service";

function getAuthToken(): string | null {
  return inject(AuthService).token || null;
}

function addAuthorizationHeader(req: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
  return req.clone({
    headers: req.headers.append('Authorization', `Bearer ${token}`)
  });
}

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const authService = inject(AuthService);
  const notiflixService = inject(NotiflixService);
  const modalService = inject(ModalService);
  const router = inject(Router);
  const token = getAuthToken();
  const request = token ? addAuthorizationHeader(req, token) : req;

  return next(request).pipe(
    catchError(error => {

      let message = 'Um erro inesperado ocorreu.';
      let status = error.status;

      if (status === 401) {
        message = 'Não autenticado ou a sessão expirou.'
        authService.deleteSession();
        router.navigate(['/login']);
        modalService.destroy();

      }else if (status === 0) {
        message = 'Erro de comunicação com a api (status 0)'
      }else{
        message = error.error.message;
      }

      if (error instanceof TimeoutError) {
        if (authService.env === 'development') {
          console.error('Erro capturado no interceptor (TIMEOUT):', error);
        }
      } else {
        if (authService.env === 'development') {
          console.error('Erro capturado no interceptor: ', error);
        }
      }

      const errorResponse = {
        message: message,
        status: status,
        error: error
      };
      return throwError(() => error);
    })
  );
}
