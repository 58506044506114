{
  "name": "frontend",
  "version": "2.3.4",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration=development",
    "test": "ng test",
    "dev": "ng serve --configuration=development --host 0.0.0.0 --port 8100",
    "build:test": "ng build --configuration=test",
    "build:prod": "ng build --configuration=production",
    "gcomp": "ng generate c components/$npm_config_name/",
    "gmod": "ng generate m modules/$npm_config_name/",
    "gguard": "ng generate g guards/$npm_config_name/ --skip-tests=false",
    "ginter": "ng generate interceptor interceptors/$npm_config_name/",
    "gpage": "ng generate c pages/$npm_config_name/",
    "gpipe": "ng generate pipe pipes/$npm_config_name/",
    "gserv": "ng generate service services/$npm_config_name/",
    "glayout": "ng generate c layouts/Layout-$npm_config_name/"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.0",
    "@angular/cdk": "^18.2.8",
    "@angular/common": "^18.2.0",
    "@angular/compiler": "^18.2.0",
    "@angular/core": "^18.2.0",
    "@angular/forms": "^18.2.0",
    "@angular/platform-browser": "^18.2.0",
    "@angular/platform-browser-dynamic": "^18.2.0",
    "@angular/router": "^18.2.0",
    "@angular/service-worker": "^18.2.0",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-free": "^6.6.0",
    "animate.css": "^4.1.1",
    "moment": "^2.30.1",
    "ng2-charts": "^4.0.0",
    "ngx-device-detector": "^8.0.0",
    "ngx-mask": "^17.0.8",
    "ngx-webcam": "^0.4.1",
    "notiflix": "^3.2.7",
    "rxjs": "~7.8.0",
    "signature_pad": "^5.0.3",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.3",
    "@angular/cli": "^18.2.3",
    "@angular/compiler-cli": "^18.2.0",
    "@types/jasmine": "~5.1.0",
    "autoprefixer": "^10.4.20",
    "jasmine-core": "~5.2.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.45",
    "tailwindcss": "^3.4.11",
    "typescript": "~5.5.2"
  }
}
